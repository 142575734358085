import MainDesign from "../../../components/ui/MainDesign";
import Meta from "../../../components/common/Meta";
import OptIn from "../../../components/guest/funnel/OptIn";
import FooterCopyright from "../../../components/common/footer/FooterCopyright";
import React from "react";

export default function OptInPage() {
    return <>
        <Meta
            title="Zapisz się na webinar."
            description="Zarejestruj się na bezpłatny webinar pod tytułem 'Jak zostać NAJLEPSZYM uczniem' (dla rodziców)."
            imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/webinar/webinar-optin-icon.png'
        />
        <MainDesign containerStyles="container pt-10 pt-md-20 pb-10" minHeight='100vh'>
            <OptIn/>
            <div className='mt-24'/>
            <FooterCopyright/>
        </MainDesign>
    </>
}