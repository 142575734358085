// src/RegisterComponent.tsx

import React, {useState} from 'react';
import {confirmSignUp, resendSignUpCode, signIn, signUp} from 'aws-amplify/auth';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import MainDesign from '../../ui/MainDesign';
import SmallSquareLogo from '../../ui/logo/SmallSquareLogo';
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {AuthService} from "../../../service/AuthService";
import {normalizeName, validateEmail} from "../../../util/Util";
import ReactPixel from "react-facebook-pixel";

function getTranslation(errorMessage: string) {
    switch (errorMessage) {
        case 'Password did not conform with policy: Password not long enough': {
            return 'Podane hasło musi składać się co najmniej z 8 znaków.';
        }
        case 'Password did not conform with policy: Password must have lowercase characters': {
            return 'Podane hasło musi składać się co najmniej z jednej małej litery.';
        }
        case 'Password did not conform with policy: Password must have uppercase characters': {
            return 'Podane hasło musi składać się co najmniej z jednej dużej litery.';
        }
        case 'Password did not conform with policy: Password must have numeric characters': {
            return 'Podane hasło musi składać się co najmniej z jednej cyfry.';
        }
        case 'User already exists': {
            return 'Podany email jest już zarejestrowany. Sprawdź swoją skrzynkę pocztową, aby potwierdzić rejestrację.';
        }
        case 'Invalid verification code provided, please try again.': {
            return 'Podany kod jest nieprawidłowy.';
        }
        case 'User is already confirmed.': {
            return 'Podany użytkownik jest już zarejestrowany.'
        }
        case 'User cannot be confirmed. Current status is CONFIRMED': {
            return 'Podany użytkownik został już aktywowany. Zaloguj się do swojego konta.'
        }
        case 'Attempt limit exceeded, please try after some time.': {
            return 'Przekroczono limit prób. Spróbuj ponownie za chwilę.'
        }
    }
    return errorMessage;
}

export default function RegisterComponent() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [infoMessage, setInfoMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [step, setStep] = useState(1); // Step 1: Sign Up, Step 2: Confirm Sign Up
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const isInputValid = validateEmail(email) && password.length >= 5 && name.trim().length >= 2;

    const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        try {
            await signUp({
                username: email,
                password: password,
                options: {
                    userAttributes: {
                        email: email,
                        name: normalizeName(name),
                        "custom:confirmationCode": searchParams.get("source") ?? ''
                    },
                },
            });
            setErrorMessage('');
            setStep(2);
        } catch (error: any) {
            console.error('Error signing up', error);
            if (error.name === 'UsernameExistsException') {
                // User already exists but may not have confirmed their email
                // Optionally, resend the verification code
                try {
                    setErrorMessage('');
                    await resendSignUpCode({username: email});
                    setStep(2); // Proceed to step 2
                    setInfoMessage('Kod został wysłany ponownie. Sprawdź swoją skrzynkę pocztową. Pamiętaj, aby użyć kodu z najnowszej wiadomości.')
                } catch (resendError: any) {
                    console.error('Error resending code', resendError);
                    console.error(resendError.message);
                    setErrorMessage(getTranslation(resendError.message));
                }
            } else {
                if (error.message && error.message.includes('PreSignUp failed with error')) {
                    setErrorMessage(error.message.replace('PreSignUp failed with error ', ''));
                } else {
                    setErrorMessage(error.message || 'Wystąpił błąd. Spróbuj ponownie później.');
                }
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleConfirmSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        try {
            const {isSignUpComplete} = await confirmSignUp({
                username: email,
                confirmationCode: verificationCode,
            });

            if (isSignUpComplete) {
                await signIn({
                    username: email,
                    password: password,
                });
            }

            try {
                ReactPixel.track('SubmitApplication');
            } catch (error) {
                console.log(error);
            }
            navigate('/'); // Adjust the path as needed
        } catch (error: any) {
            console.error('Error confirming sign up', error);
            setErrorMessage(error.message || 'Error confirming sign up');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            {step === 1 && (
                <MainDesign containerStyles="container py-28">
                    <div className="mb-7 text-center mx-auto mw-md-lg">
                        <SmallSquareLogo width="58px" disableLink={true}/>

                        <h2 className="font-heading mb-4 fs-7 mt-8">Dołącz do tysięcy korepetytorów</h2>
                        <p className="mb-0 fs-9 fw-medium text-secondary-light">
                            I zacznij korzystać z najlepszej tablicy na korki!
                        </p>
                    </div>
                    <form className="mw-sm mx-auto" onSubmit={handleSignUp}>
                        <div className="mb-4 row g-6">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="mb-1 fw-medium text-white" htmlFor="signUpInput1-1">
                                        Imię
                                    </label>
                                    <input
                                        className="form-control text-dark shadow"
                                        id="signUpInput1-1"
                                        type="text"
                                        placeholder="Podaj swoje imię"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="mb-1 fw-medium text-white" htmlFor="signUpInput1-2">
                                        Email
                                    </label>
                                    <input
                                        className="form-control text-dark shadow"
                                        id="signUpInput1-2"
                                        type="email"
                                        placeholder="Podaj swój email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="mb-1 fw-medium text-white" htmlFor="signUpInput1-3">
                                        Hasło
                                    </label>
                                    <input
                                        className="form-control text-dark shadow"
                                        id="signUpInput1-3"
                                        type="password"
                                        placeholder="Podaj swoje hasło"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="mb-3">
                                <p className="text-danger">{getTranslation(errorMessage)}</p>
                            </div>
                        )}
                        <p className="mb-1 fs-14 fw-medium text-left mt-2 text-white">
                            <span>Rejestrując się, akceptujesz </span>
                            <Link className="text-success link-success" to="/polityka-prywatnosci">
                                politykę prywatności
                            </Link>
                            <span> oraz </span>
                            <Link className="text-success link-success" to="/regulamin">
                                regulamin
                            </Link>
                            <span>.</span>
                        </p>
                        <div className="mb-4 row g-4">
                            <div className="col-12">
                                <AnimatedButton
                                    className="btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow"
                                    type="submit"
                                    disabled={isSubmitting || !isInputValid}
                                >
                                    Zarejestruj się
                                </AnimatedButton>
                            </div>
                        </div>
                        <div className='mb-6 row g-4'>
                            <div className='col-12'>
                                <AnimatedButton
                                    className='btn btn-lg btn-outline-light d-flex flex-wrap align-items-center justify-content-center fs-11 py-3 w-100 text-white shadow'
                                    onClick={() => AuthService.facebookLogin()}
                                    bounceEffect
                                    type='button'
                                    customScale={1.05}
                                    disabled={isSubmitting}
                                >

                                    <img
                                        className='me-2'
                                        style={{width: "24px"}}
                                        src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/social/social-fb.png'
                                        alt='logo facebook'
                                    />
                                    <span>Kontynuuj z Facebookiem</span>
                                </AnimatedButton>
                            </div>
                        </div>
                        <p className="mb-0 fs-13 fw-medium text-white text-center">
                            <span>Masz już konto? </span>
                            <Link className="text-success link-success" to="/logowanie">
                                Zaloguj się
                            </Link>
                        </p>
                    </form>
                </MainDesign>
            )}

            {step === 2 && (
                <MainDesign containerStyles="container py-28">
                    <div className="mb-7 text-center mx-auto mw-md-lg">
                        <h2 className="font-heading mb-4 fs-7">Potwierdź swój email</h2>
                        <p className="mb-0 fs-9 fw-medium text-secondary-light">
                            Wpisz kod weryfikacyjny wysłany na {email}. Jeżeli nie widzisz wiadomości, sprawdź folder
                            spam.
                        </p>
                    </div>
                    <form className="mw-sm mx-auto" onSubmit={handleConfirmSignUp}>
                        <div className="mb-4 row g-6">
                            <div className="col-12">
                                <div className="form-group">
                                <label className="mb-1 fw-medium text-white" htmlFor="verificationCode">
                                        Kod weryfikacyjny
                                    </label>
                                    <input
                                        className="form-control text-dark shadow"
                                        id="verificationCode"
                                        type="text"
                                        placeholder="Kod weryfikacyjny"
                                        value={verificationCode}
                                        onChange={(e) => setVerificationCode(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="mb-3">
                                <p className="text-danger">{getTranslation(errorMessage)}</p>
                            </div>
                        )}
                        {infoMessage && (
                            <div className="mb-3">
                                <p className="text-success">{infoMessage}</p>
                            </div>
                        )}
                        <div className="mb-6 row g-4">
                            <div className="col-12">
                                <AnimatedButton
                                    className="btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Potwierdź
                                </AnimatedButton>
                            </div>
                        </div>
                        <p className="mb-0 fs-13 fw-medium text-white text-center">
                            <span>Nie otrzymałeś/aś kodu? </span>
                            <button
                                type="button"
                                className="text-success link-success btn btn-link p-0"
                                onClick={async () => {
                                    try {
                                        await resendSignUpCode({username: email});
                                        setInfoMessage('Kod został wysłany ponownie. Sprawdź swoją skrzynkę pocztową. Pamiętaj, aby użyć kodu z najnowszej wiadomości.');
                                    } catch (err: any) {
                                        setInfoMessage('');
                                        console.error('Error resending code: ', err);
                                        if (err.name === 'LimitExceededException') {
                                            setErrorMessage('Przekroczyłeś liczbę prób. Spróbuj ponownie za chwilę.');
                                        } else {
                                            setErrorMessage('Wystąpił błąd podczas wysyłania kodu. Spróbuj ponownie.');
                                        }
                                    }
                                }}
                            >
                                Wyślij ponownie
                            </button>
                        </p>
                    </form>
                </MainDesign>
            )}
        </>
    );
}