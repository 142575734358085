import Heading from "../../ui/Heading";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import AdvantageItem from "../../ui/element/item/AdvantageItem";
import {useSearchParams} from "react-router-dom";

export default function OptIn() {
    const [searchParams] = useSearchParams();

    const source = searchParams.get('source') ?? 'optin';

    return <>
        <div className="d-block d-md-none">
            <p className="text-white text-center fs-12 mb-5">
                Przetestuj w <b>100% bezpłatnie</b> już teraz.
            </p>
            <Heading
                customStyles="mb-0 text-center mw-md-5xl mx-auto"
                title={"Tablica stworzona specjalnie dla"}
                customTitleStyles="font-heading mb-1 fs-4 text-white"
            />
            <Heading
                customStyles="mb-0 text-center mw-md-5xl mx-auto mt-0"
                title={"KOREPETYTORÓW"}
                customTitleStyles="font-heading mb-1 fs-3 text-warning"
            />
            <p className="text-warning fs-10 fw-medium text-center mx-1">
                <i>Bo prowadzenie zajęć powinno być przyjemne. Pracuj wygodnie, bez żadnych limitów ani ograniczeń.</i>
            </p>
        </div>

        {/* Header (Desktop) */}
        <div className="d-none d-md-block">
            <p className="text-white text-center fs-11">
                Przetestuj w <b>100% bezpłatnie</b> już teraz.
            </p>
            <Heading
                customStyles="mb-0 text-center mw-md-5xl mx-auto"
                title={"Tablica stworzona specjalnie dla"}
                customTitleStyles="font-heading mb-1 fs-5 text-white"
            />
            <Heading
                customStyles="mb-0 text-center mw-md-5xl mx-auto mt-0"
                title={"KOREPETYTORÓW"}
                customTitleStyles="font-heading mb-1 fs-3 text-warning"
            />
            <p className="text-warning fs-10 fw-medium text-center">
                <i>Bo prowadzenie zajęć powinno być przyjemne.<br/> Pracuj wygodnie, bez żadnych limitów ani ograniczeń.</i>
            </p>
        </div>

        <div className='text-center mw-md-2xl mx-auto mt-6 mb-6'>
            <AnimatedLink
                className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                to={`/rejestracja?source=${source}`}
            >
                PRZETESTUJ JUŻ TERAZ
            </AnimatedLink>
        </div>

        <div className='row mt-8'>
            <div className='col-md-6 col-12 text-center'>
                <div style={{position: 'relative', width: '100%', paddingTop: '62.25%'}}>
                    <img
                        data-src='https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/funnel/optin-tnk-showcase.png'
                        className='img-fluid lazyload' alt='mistrzowskie wsparcie'
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                    />
                </div>
            </div>
            <div className='col-md-6 col-12 mt-4 mt-md-2'>
                <div className='container'>
                    <Heading customTitleStyles='font-heading mb-4 fs-5 text-white'
                             customStyles='mb-6 text-md-left mw-md-4xl mx-auto' title='Jak to działa?'/>
                    <AdvantageItem
                        title={`Twórz uczniów i przypisuj im poszczególne tablice. Porządek i prostota.`}/>
                    <AdvantageItem
                        titleHtml={<span>Uczeń <b>nie musi</b> tworzyć konta, aby móc edytować tablicę i przesyłać pliki.</span>}/>
                    <AdvantageItem
                        title='Udostępniaj wszystkie tablice dla danego ucznia jednym kliknięciem. (Rodzice lubią to 😎)'/>
                    <AdvantageItem
                        title='Różne tła? Różne kształty? Dodawanie obrazów? Tekst? Wiele stron? Nie ma problemu. To Ty decydujesz.'/>
                    <AdvantageItem
                        title='Jednym kliknięciem eksportuj całą tablicę w wysokiej jakości.'/>

                    <div className='text-center mw-md-2xl mx-auto mt-6 mb-6'>
                        <AnimatedLink
                            className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                            to={`/rejestracja?source=${source}`}
                        >
                            PRZETESTUJ JUŻ TERAZ
                        </AnimatedLink>
                    </div>
                </div>
            </div>
        </div>
    </>
}