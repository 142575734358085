import MainDesign from "../../ui/MainDesign";
import React from "react";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import SmallSquareLogo from "../../ui/logo/SmallSquareLogo";
import FooterCopyright from "./FooterCopyright";

interface LinkProps {
    name: string
    to: string
}

interface FooterProps {
    links: LinkProps[]
    isGuest: boolean
}

export default function Footer(props: FooterProps) {
    const {links, isGuest} = props;
    return <>
        <MainDesign minHeight="300px" containerStyles="container pt-20" removeSectionOverflowHidden={true}
                    hideBackgroundImage={true} minHeightChildrenDiv='300px' disableAnimation={true}>
            <SmallSquareLogo/>
            <ul className="nav justify-content-center mb-5">
                {
                    links.map((link, index) => {
                        return <li key={index} className="nav-item"><AnimatedLink
                            className="nav-link px-6 pt-4 fs-11 link-secondary-light"
                            to={link.to}>{link.name}</AnimatedLink></li>
                    })
                }
            </ul>
            {
               !isGuest && <p className='mb-0 pb-8 fs-12 fw-medium text-center text-secondary-light'>
                    <span className='text-success'>Masz pytanie?</span> kontakt@tablicanakorki.pl
                </p>
            }

           <FooterCopyright/>
        </MainDesign>
    </>
}