import {DefaultMainMenu, Editor, exportToBlob, TldrawUiMenuGroup, TldrawUiMenuItem} from "tldraw";
import React, {useCallback} from "react";

export function CustomMainMenu(editor: Editor, exportFileTitle: string, showEndLessonButton: boolean, showEndLessonButtonText?: string, callOnEndLesson?: () => void) {
    const handleExport = useCallback(async () => {
        if (!editor) return;

        try {
            // Get all shape IDs from the current page
            const shapeIds = editor.getCurrentPageShapeIds();

            if (shapeIds.size === 0) {
                alert('Tablica jest pusta.');
                return;
            }

            // Export the board to a PNG blob
            const blob = await exportToBlob({
                editor,
                // @ts-ignore
                ids: [...shapeIds], // Convert the Set to an Array
                format: 'png',
                opts: { background: true, scale: 1 }, // Scale of 1 for full resolution
            });

            // Create a URL for the blob
            const url = URL.createObjectURL(blob);

            // Create an anchor element to trigger the download
            const a = document.createElement('a');
            a.href = url;
            const now = new Date();
            const formattedTime = now.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false, // 24-hour format
            }).replace(/:/g, '-'); // Replace colons with dashes for filename compatibility
            a.download = `${exportFileTitle} - ${formattedTime}.png`; // Default filename
            document.body.appendChild(a);
            a.click();

            // Clean up the URL object
            URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error exporting the board:', error);
            alert('Failed to export the board. Please try again.');
        }
    }, [editor]);

    return (
        <DefaultMainMenu>
            {
                showEndLessonButton && <div>
                    {/*@ts-ignore*/}
                    <TldrawUiMenuGroup id="end-lesson">
                        <TldrawUiMenuItem
                            id="end-lesson"
                            label={showEndLessonButtonText ?? "Zakończ lekcję"}
                            icon="external-link"
                            readonlyOk
                            onSelect={() => {
                                if (callOnEndLesson) {
                                    callOnEndLesson();
                                }
                            }}
                        />
                    </TldrawUiMenuGroup>
                </div>
            }
            <div>
                {/* @ts-ignore*/}
                <TldrawUiMenuGroup id="add-image">
                    <TldrawUiMenuItem
                        id="add-image"
                        label="Eksportuj"
                        icon="external-link"
                        readonlyOk
                        onSelect={handleExport} // Trigger image addition
                    />
                </TldrawUiMenuGroup>
            </div>
            <div>
                {/* @ts-ignore*/}
                <TldrawUiMenuGroup id="add-image">
                    <TldrawUiMenuItem
                        id="add-image"
                        label="Zmień motyw"
                        icon="external-link"
                        readonlyOk
                        onSelect={() => {
                            const wasLight = editor.user.getUserPreferences().colorScheme === 'light';
                            editor.user.updateUserPreferences({colorScheme:  wasLight ? 'dark' : 'light'})

                            document.body.style.background = wasLight ? '#101011' : '#fefeff';
                        }}
                    />
                </TldrawUiMenuGroup>
            </div>
        </DefaultMainMenu>
    )
}