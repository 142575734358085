import React from "react";

export default function FooterCopyright() {
    return <>
        <div
            className='position-relative border-bottom border-light-dark'
        />
        <div className="position-relative container" style={{zIndex: 1}}>
            <p className="mb-0 pt-8 pb-8 fs-11 fw-medium text-center text-secondary-light">© 2025
                TablicaNaKorki.pl | Wszelkie prawa zastrzeżone.</p>
        </div>
    </>
}