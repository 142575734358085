import {HttpMethod, HttpService} from "./HttpService";
import {
    ArchiveStudentRequest,
    ChangeAllBoardsSharingRequest,
    CreateOrEditStudent,
    Student,
    StudentResponse
} from "../model/tutor/Student";

export class TutorService {
    public static async getStudent(id: string): Promise<StudentResponse> {
        return HttpService.sendRequest<StudentResponse>(
            HttpMethod.GET,
            `/students/${id}`,
        );
    }

    public static async getAllStudents(): Promise<Student[]> {
        return HttpService.sendRequest<Student[]>(
            HttpMethod.GET,
            `/students`,
        );
    }

    public static async addStudent(student: CreateOrEditStudent): Promise<Student> {
        return HttpService.sendRequest<Student>(
            HttpMethod.POST,
            `/students`,
            JSON.stringify(student),
        );
    }

    public static async editStudent(student: CreateOrEditStudent): Promise<Student> {
        return HttpService.sendRequest<Student>(
            HttpMethod.PUT,
            `/students`,
            JSON.stringify(student),
        );
    }

    public static async deleteStudent(id: string): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.DELETE,
            `/students/${id}`,
        );
    }

    public static async changeBoardSharingAllowed(studentId: string, allowed: boolean): Promise<Student> {
        return HttpService.sendRequest<Student>(
            HttpMethod.POST,
            `/students/boardSharing`,
            JSON.stringify({
                studentId: studentId,
                isBoardSharingAllowed: allowed
            } as ChangeAllBoardsSharingRequest),
        );
    }

    public static async toggleArchive(studentId: string, shouldBeArchived: boolean): Promise<Student> {
        return HttpService.sendRequest<Student>(
            HttpMethod.POST,
            `/students/archive`,
            JSON.stringify({
                studentId: studentId,
                shouldBeArchived: shouldBeArchived
            } as ArchiveStudentRequest),
        );
    }
}