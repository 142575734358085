import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import MainDesign from "../ui/MainDesign";

interface RedirectComponentProps {
    to: string,
    from?: string
}

export default function RedirectComponent({to, from}: RedirectComponentProps) {
    const navigate = useNavigate();

    useEffect(() => {
        if (from) {
            window.location.href = `${to}?source=${from}`;
        } else {
            window.location.href = to;
        }
    }, [to, navigate]);

    return <MainDesign minHeight='100vh'>
        <p></p>
    </MainDesign>;
};