import React, {useEffect} from "react";
import GuestFooter from "../components/guest/GuestFooter";
import {Outlet, useLocation, useRouteLoaderData} from "react-router-dom";
import ScrollToTop from "../util/ScrollToTop";
import {TokenPayload} from "../service/AuthService";
import TutorHeader from "../components/tutor/TutorHeader";
import GuestHeader from "../components/guest/GuestHeader";
import TutorFooter from "../components/tutor/TutorFooter";
import {useCookies} from "react-cookie";
import CookieBanner from "../components/common/CookieBanner";
import AdminHeader from "../components/admin/AdminHeader";
import Clarity from "@microsoft/clarity";
import ReactPixel from 'react-facebook-pixel';

export default function RootLayout() {
    const [cookies, setCookie] = useCookies(["cookieConsent"]);

    useEffect(() => {
        // If cookie consent is already given, load scripts immediately
        if (cookies.cookieConsent) {
            giveCookieConsent();
        } else {
            activateFbPixelWithoutCookies();
        }
    }, [cookies]);

    const token = useRouteLoaderData('root') as TokenPayload;
    const location = useLocation();

    // Check if the current path starts with /t/
    const isOnWhiteboard = location.pathname.startsWith('/t/');

    const isOnOptIn = location.pathname.startsWith('/optin');

    const isOnAdminPanel = location.pathname.startsWith('/admin');

    function addCookieCookiesConsent(consent: boolean) {
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        setCookie("cookieConsent", consent, {path: "/", expires: oneYearFromNow});
    }

    function giveCookieConsent() {
        addCookieCookiesConsent(true);
        loadGoogleAnalytics();
        Clarity.consent(true);
        activateFbPixel();
    }

    function rejectCookieConsent() {
        addCookieCookiesConsent(false);
        activateFbPixelWithoutCookies();
    }

    function loadGoogleAnalytics() {
        try {
            // @ts-ignore
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'analytics_storage': 'granted'
            });
        } catch (e) {
            console.log(e);
        }
    }

    function activateFbPixel() {
        console.log('pixel acticated')
        ReactPixel.init('1243862806687674', undefined, {debug: false, autoConfig: true});
        ReactPixel.grantConsent();
    }

    function activateFbPixelWithoutCookies() {
        ReactPixel.init('1243862806687674', undefined, {debug: false, autoConfig: true});
        ReactPixel.revokeConsent();
    }

    return (
        <>
            <ScrollToTop/>
            {
                isOnAdminPanel && <AdminHeader/>
            }
            {
                !isOnAdminPanel && !isOnWhiteboard && !isOnOptIn && (location.pathname !== '/' || token ? <TutorHeader/> :
                    <GuestHeader/>)
            }
            <Outlet/>
            {
                !isOnWhiteboard && !isOnOptIn && (token ? <TutorFooter/> : <GuestFooter/>)
            }
            {(cookies.cookieConsent === undefined || cookies.cookieConsent === null) &&
                <CookieBanner giveCookieConsent={giveCookieConsent} rejectCookieConsent={rejectCookieConsent}/>}
        </>
    );
}